* {
  margin:0;
  padding:0;
}

html, 
body {
  font-size: 18;
  background-color: #f5f6f7;
}

h1 {
  max-width: 940px;
  font-size: 2.5em;
  font-family: Lato, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  margin: 40px 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contenedor-principal {
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}